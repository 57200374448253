import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Wordle from './Wordle';

interface GameData {
  game: string;
  team: string;
  formation: string;
  lineup: string[];
  positions: string[];
}

interface PlayerState {
  guesses: string[];
  completed: boolean;
  attempts: number;
}

const App: React.FC = () => {
  const [gameData, setGameData] = useState<GameData | null>(null);
  const [modalVisible, setModalVisible] = useState(true);
  const [wordleVisible, setWordleVisible] = useState(false);
  const [currentPlayer, setCurrentPlayer] = useState<string | null>(null);
  const [playerStates, setPlayerStates] = useState<{ [key: string]: PlayerState }>({});
  const [gameOver, setGameOver] = useState(false);
  const [gameCompleted, setGameCompleted] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const gameOverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalVisible && modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setModalVisible(false);
      }
    };
  
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalVisible]);
  
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    axios
      .get<GameData>('https://duswss3mnq5lncpjlj4guubg6y0rryng.lambda-url.us-east-1.on.aws')
      .then((response) => setGameData(response.data))
      .catch((error) => console.error('Error fetching game data:', error));
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (gameOverRef.current && !gameOverRef.current.contains(event.target as Node)) {
        setShowResults(false);
      }
    };

    if (gameOver) {
      window.addEventListener('mousedown', handleClickOutside);
    } else {
      window.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [gameOver]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const openWordle = (player: string) => {
    setCurrentPlayer(player);
    setWordleVisible(true);
  };

  const closeWordle = () => {
    setWordleVisible(false);
  };

  const handleWordleComplete = (player: string, state: PlayerState) => {
    setPlayerStates((prev) => {
      const newStates = { ...prev, [player]: state };
      const allCompleted = gameData!.lineup.every((p) => newStates[p]?.completed);

      if (allCompleted) {
        setGameCompleted(true);
        setGameOver(true);
      } else if (state.attempts >= 9) {
        setGameOver(true);
      }

      return newStates;
    });

    setTimeout(() => {
      if (state.completed) {
        closeWordle();
      }
    }, 500);
  };

  if (!gameData) return <div>Loading...</div>;

  const formationArray = gameData.formation.split('-').map((num) => parseInt(num));
  const rows: string[][] = [];
  let index = 1; // start from 1 because index 0 is the goalkeeper

  rows.push([gameData.lineup[0]]); // first row for the goalkeeper

  for (const num of formationArray) {
    const row = [];
    for (let i = 0; i < num; i++) {
      row.push(gameData.lineup[index]);
      index++;
    }
    rows.push(row);
  }

  const totalGuesses = Object.values(playerStates).reduce((acc, state) => acc + state.guesses.length, 0);


  const generateCopyableTable = () => {
    if (!gameData) return '';

    let table = `${gameData.game}\n${gameData.team}\n${gameData.formation}\n\nSCORE: ${totalGuesses}\n\n`;
    const formationArray = gameData.formation.split('-').map(num => parseInt(num));
    const rows: string[][] = [];
    let index = 1; // start from 1 because index 0 is the goalkeeper

    // First row for the goalkeeper
    rows.push([gameData.lineup[0]]);

    for (const num of formationArray) {
      const row = [];
      for (let i = 0; i < num; i++) {
        row.push(gameData.lineup[index]);
        index++;
      }
      rows.push(row);
    }

    // Function to generate row with correct player placement and green padding
    const generateRow = (players: string[]) => {
      let rowString = '';

      switch (players.length) {
        case 0:
          rowString = '🟩🟩🟩🟩🟩🟩🟩🟩🟩';
          break;
        case 1:
          rowString = '🟩🟩🟩🟩' + getPlayerEmoji(players[0]) + '🟩🟩🟩🟩';
          break;
        case 2:
          rowString = '🟩🟩🟩' + getPlayerEmoji(players[0]) + '🟩' + getPlayerEmoji(players[1]) + '🟩🟩🟩';
          break;
        case 3:
          rowString = '🟩🟩' + getPlayerEmoji(players[0]) + '🟩' + getPlayerEmoji(players[1]) + '🟩' + getPlayerEmoji(players[2]) + '🟩🟩';
          break;
        case 4:
          rowString = '🟩' + getPlayerEmoji(players[0]) + '🟩' + getPlayerEmoji(players[1]) + '🟩' + getPlayerEmoji(players[2]) + '🟩' + getPlayerEmoji(players[3]) + '🟩';
          break;
        case 5:
          rowString = getPlayerEmoji(players[0]) + '🟩' + getPlayerEmoji(players[1]) + '🟩' + getPlayerEmoji(players[2]) + '🟩' + getPlayerEmoji(players[3]) + '🟩' + getPlayerEmoji(players[4]);
          break;
        default:
          break;
      }

      return rowString;
    };

    // Helper function to get player emoji based on their state
    const getPlayerEmoji = (player: string) => {
      const playerState = playerStates[player];

      if (playerState?.completed) {
        return `${playerState.attempts}️⃣`; // Number emoji based on attempts
      } else if (playerState?.attempts === 9) {
        return '❌'; // X for players with 9 attempts
      } else if (playerState) {
        return `${playerState.attempts}️⃣`; // Number emoji based on attempts
      } else {
        return '0️⃣'; // 0 emoji for unattempted players
      }
    };

    // Generate emoji table based on player state
    rows.forEach((row, rowIndex) => {
      if (rowIndex > 0) table += '🟩🟩🟩🟩🟩🟩🟩🟩🟩\n'; // Add green row between rows
      table += generateRow(row) + '\n'; // Centered player row
    });

    return table;
  };

  const copyTableToClipboard = () => {
  const table = generateCopyableTable();

  if (navigator.clipboard && navigator.clipboard.writeText) {
    // Modern API to copy
    navigator.clipboard.writeText(table)
      .catch((err) => console.error('Failed to copy table using clipboard API:', err));
  } else {
    // Fallback method for older browsers
    const textArea = document.createElement('textarea');
    textArea.value = table;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      console.log('Table copied using fallback method!');
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
    }
   };


  return (
    <AppContainer>
      <h1>Tren Donmez Guessing Game</h1>

      <GameInfo>
        <h2>Game Info</h2>
        <p><strong>Game:</strong> {gameData.game}</p>
        <p><strong>Team:</strong> {gameData.team}</p>
        <p><strong>Formation:</strong> {gameData.formation}</p>
      </GameInfo>

      {modalVisible && (
        <Modal>
            <ModalContent ref={modalRef}>
            <h2>{gameData.game}</h2>
            <p>Team: {gameData.team}</p>
            <p>Formation: {gameData.formation}</p>
            <button onClick={closeModal}>Close</button>
            </ModalContent>
        </Modal>
      )}

      <FormationContainer>
        {rows.map((row, rowIndex) => (
          <Row key={rowIndex} columns={row.length}>
            {row.map((player, playerIndex) => (
              <Player
                key={playerIndex}
                onClick={() => openWordle(player)}
                className={playerStates[player]?.completed ? 'completed' : ''}
              >
                <PlayerInfo>
                  <div>
                    {playerStates[player]?.completed
                      ? player
                      : player
                          .split('')
                          .map((char, i) => (char === "'" ? "'" : '*'))
                          .join('')}
                  </div>
                  <div>{player.replace(/'/g, '').length}</div>
                </PlayerInfo>
                {playerStates[player]?.attempts > 0 && (
                <AttemptsBadge completed={playerStates[player]?.completed}>
                    {playerStates[player]?.attempts}
                </AttemptsBadge>
                )}
              </Player>
            ))}
          </Row>
        ))}
      </FormationContainer>

      {wordleVisible && currentPlayer && (
        <WordlePopup>
          <Wordle
            player={currentPlayer}
            state={playerStates[currentPlayer] || { guesses: [], completed: false, attempts: 0 }}
            onComplete={(state) => handleWordleComplete(currentPlayer, state)}
            onClose={closeWordle}
          />
        </WordlePopup>
      )}

      <TotalGuesses>Total Guesses: {totalGuesses}</TotalGuesses>

      {gameOver && showResults && (
        <GameOverMessage ref={gameOverRef}>
          {gameCompleted ? (
            <div>
              <h2>Congratulations! You completed the game!</h2>
              <AttemptsTable>
                <thead>
                  <tr>
                    <th>Player</th>
                    <th>Attempts</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(playerStates).map(([player, state]) => (
                    <tr key={player}>
                      <td>{player}</td>
                      <td>{state.attempts}</td>
                    </tr>
                  ))}
                </tbody>
              </AttemptsTable>
              <TotalGuesses>Total Guesses: {totalGuesses}</TotalGuesses>
              <br></br>
              <button onClick={copyTableToClipboard}>Copy Formation to Clipboard</button>
            </div>
          ) : (
            <div>
              <h2>Game Over ://</h2>
              <AttemptsTable>
                <thead>
                  <tr>
                    <th>Player</th>
                    <th>Attempts</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(playerStates).map(([player, state]) => (
                    <tr key={player}>
                      <td>{player}</td>
                      <td>{state.attempts}</td>
                    </tr>
                  ))}
                </tbody>
              </AttemptsTable>
              <TotalGuesses>Total Guesses: {totalGuesses}</TotalGuesses>
              <br></br>
              <button onClick={copyTableToClipboard}>Copy Formation to Clipboard</button>
            </div>
          )}
        </GameOverMessage>
      )}
      {gameOver && !showResults && (
        <ShowResultsButton onClick={() => setShowResults(true)}>Show Results</ShowResultsButton>
      )}
    </AppContainer>
  );
};

export default App;

const AppContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #2e2e2e; /* Dark background for better contrast */
  color: #f0f0f0; /* Light text color */
  padding: 20px;
`;

const GameInfo = styled.div`
  margin-top: 8px;
  background-color: #333; /* Dark background for game info */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Darker shadow for consistency */
  color: #f0f0f0; /* Light text for contrast */
  text-align: left;
  width: 80%;
  max-width: 500px;
`;


const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
`;

const ModalContent = styled.div`
  background-color: #3e3e3e; /* Darker modal background */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: white; /* White text for readability */
`;

const FormationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  padding: 10px;
  background-color: #444; /* Slightly darker background for the formation */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;


const Row = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 10px;
  width: 100%;
  justify-items: center;
  margin-bottom: 10px;
`;

const Player = styled.div`
  width: 80px;
  height: 80px;
  background-color: #76a5af;
  color: white;
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  position: relative;

  &.completed {
    background-color: #4CAF50; /* Green for completed players */
  }

  &:hover {
    background-color: #00796b;
  }
`;

const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    margin-top: 5px;
  }
`;

const AttemptsBadge = styled.div<{ completed: boolean }>`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: ${({ completed }) => (completed ? 'green' : 'red')};
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
`;

const WordlePopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GameOverMessage = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #274e13; /* Dark background for result table */
  padding: 40px; /* Increased padding for larger space inside */
  width: 80%; /* Increase width to make the message box bigger */
  max-width: 600px; /* Max width to prevent it from being too large */
  border-radius: 15px; /* Slightly more rounded corners */
  text-align: center;
  color: white; /* Light text for readability */
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.7); /* Add a shadow for emphasis */
`;

const AttemptsTable = styled.table`
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
  background-color: #111; /* Darker background for the table */
  color: #f0f0f0; /* Light text color for table content */
  border-radius: 8px;

  th, td {
    border: 1px solid #555; /* Border to separate rows and columns with slight contrast */
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #555; /* Slightly darker header for table */
    color: #f0f0f0; /* White text for the headers */
  }
`;

const TotalGuesses = styled.div`
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #f0f0f0; /* Light text for contrast */
`;

const ShowResultsButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #009688;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #00796b;
  }
`;
