import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

interface WordleProps {
    player: string;
    state: PlayerState;
    onComplete: (state: PlayerState) => void;
    onClose: () => void;
}

interface PlayerState {
    guesses: string[];
    completed: boolean;
    attempts: number;
}

const Wordle: React.FC<WordleProps> = ({ player, state, onComplete, onClose }) => {
    const [guess, setGuess] = useState('');
    const [attempts, setAttempts] = useState<string[]>(state.guesses);
    const [completed, setCompleted] = useState(state.completed);
    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {    
		onClose();
            } else if (event.key === 'Enter') {
                handleEnterKey();
            } else if (event.key === 'Backspace') {
                handleBackspaceKey();
            } else if (/^[a-zA-Z]$/.test(event.key) && guess.length < player.replace(/'/g, '').length) {
                handleLetterClick(event.key.toUpperCase());
            }
        };
    
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                onClose();
            }
        };
    
        window.addEventListener('keydown', handleKeyPress);
        window.addEventListener('mousedown', handleClickOutside);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
            window.removeEventListener('mousedown', handleClickOutside);
        };
    }, [guess, attempts, player, onClose, onComplete]);

    const handleEnterKey = () => {
        if (guess.length === player.replace(/'/g, '').length && attempts.length < 9) {
            const newAttempts = [...attempts, addApostrophes(guess, player)];
            setAttempts(newAttempts);
            setGuess('');
            if (addApostrophes(guess, player).toUpperCase() === player.toUpperCase()) {
                setCompleted(true);
                setTimeout(() => onComplete({ guesses: newAttempts, completed: true, attempts: newAttempts.length }), 500);
            } else {
                setTimeout(() => onComplete({ guesses: newAttempts, completed: false, attempts: newAttempts.length }), 500);
            }
        }
    };

    const handleBackspaceKey = () => {
        setGuess(guess.slice(0, -1));
    };

    const handleLetterClick = (letter: string) => {
        if (guess.length < player.replace(/'/g, '').length) {
            setGuess(guess + letter);
        }
    };

    const addApostrophes = (input: string, template: string): string => {
        let result = '';
        let inputIndex = 0;
        for (let i = 0; i < template.length; i++) {
            if (template[i] === "'") {
                result += "'";
            } else if (inputIndex < input.length) {
                result += input[inputIndex];
                inputIndex++;
            }
        }
        return result;
    };

    const getLetterStatus = (letter: string, index: number, guess: string, player: string) => {
        if (letter === "'") return 'correct';
        if (!letter) return 'absent';

        const correctPositions = Array(player.length).fill(false);
        const presentLetters: { [key: string]: number } = {};

        // First pass: check for correct positions
        for (let i = 0; i < player.length; i++) {
            if (guess[i] === player[i]) {
                correctPositions[i] = true;
            } else if (player[i] !== "'") {
                presentLetters[player[i]] = (presentLetters[player[i]] || 0) + 1;
            }
        }

        // Second pass: check for present letters
        const status = Array(guess.length).fill('absent');
        for (let i = 0; i < guess.length; i++) {
            if (correctPositions[i]) {
                status[i] = 'correct';
            } else if (presentLetters[guess[i]]) {
                status[i] = 'present';
                presentLetters[guess[i]]--;
            }
        }

        return status[index];
    };

    const determineKeyStatus = (letter: string) => {
        let status: 'correct' | 'present' | 'absent' | 'default' = 'default';
        attempts.forEach(attempt => {
            attempt.split('').forEach((attemptLetter, index) => {
                if (attemptLetter === letter) {
                    const currentStatus = getLetterStatus(attemptLetter, index, attempt, player);
                    if (currentStatus === 'correct') status = 'correct';
                    else if (currentStatus === 'present' && status !== 'correct') status = 'present';
                    else if (status !== 'correct' && status !== 'present') status = 'absent';
                }
            });
        });
        return status;
    };

    const rows = [
        'QWERTYUIOP',
        'ASDFGHJKL',
        'ZXCVBNM'
    ];

    return (
        <WordleContainer ref={popupRef}>
            {completed && <Message>Congratulations! You found the player!</Message>}
            {attempts.map((attempt, attemptIndex) => (
                <GuessRow key={attemptIndex}>
                    {player.split('').map((_, index) => (
                        <Letter key={index} status={getLetterStatus(attempt[index], index, attempt, player)}>
                            {attempt[index] || ''}
                        </Letter>
                    ))}
                </GuessRow>
            ))}
            {attempts.length < 9 && !completed && (
                <GuessRow>
                    {player.split('').map((char, index) => (
                        <Letter key={index} status={char === "'" ? 'correct' : 'active'}>
                            {char === "'" ? "'" : (guess[index - player.slice(0, index).split("'").length + 1] || '')}
                        </Letter>
                    ))}
                </GuessRow>
            )}
            {attempts.length >= 9 && !completed && <Message>Game over ://</Message>}
            <Keyboard>
                {rows.map((row, rowIndex) => (
                    <KeyboardRow key={rowIndex}>
                        {row.split('').map((letter) => {
                            const status = determineKeyStatus(letter);
                            return (
                                <Key
                                    key={letter}
                                    status={status}
                                    onClick={() => handleLetterClick(letter)}
                                >
                                    {letter}
                                </Key>
                            );
                        })}
                    </KeyboardRow>
                ))}
                <KeyboardRow>
                    <Key status="default" onClick={handleBackspaceKey}>⌫</Key>
                    <Key status="default" onClick={handleEnterKey}>↵</Key>
                </KeyboardRow>
            </Keyboard>
        </WordleContainer>
    );
};

export default Wordle;

const WordleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #222; /* Darker background for Wordle popup */
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  color: white; /* White text for readability */
`;

const GuessRow = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
`;

const Letter = styled.div<{ status: string }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  border-radius: 5px;
  font-size: 20px;
  background-color: ${({ status }) =>
    status === 'correct' ? '#4CAF50' : /* Bright green for correct */
    status === 'present' ? '#FFC107' : /* Yellow for present */
    status === 'active' ? '#616161' :   /* Gray for active letters */
    status === 'absent' ? '#424242' :   /* Dark gray for absent letters */
    'white'};
  color: ${({ status }) => (status === 'active' ? 'white' : 'white')}; /* White text for contrast */
`;
const Keyboard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;

const KeyboardRow = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
`;
const Key = styled.div<{ status: string }>`
  width: 30px; /* Reduce size */
  height: 30px; /* Reduce size */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
  border-radius: 5px;
  font-size: 12px; /* Smaller font size */
  background-color: ${({ status }) =>
    status === 'correct' ? '#4CAF50' :
    status === 'present' ? '#FFC107' :
    status === 'absent' ? '#424242' :
    '#9E9E9E'};
  color: ${({ status }) => (status === 'default' ? 'black' : 'white')};
  pointer-events: ${({ status }) => (status === 'absent' ? 'none' : 'auto')};
  flex-shrink: 0;
  cursor: pointer; /* Add pointer for mouse users */
`;


const Message = styled.div`
  font-size: 18px;
  color: #FF5722; /* Bright red for error or game over messages */
  margin-top: 10px;
`;

